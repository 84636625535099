import React from 'react';

const  IconSort= () => {

    return (
        <svg width="14px" height="13px" viewBox="0 0 14 13">
            <g id="Designs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-732.000000, -166.000000)" stroke="#405363">
                    <g transform="translate(79.500038, 130.000000)">
                        <g transform="translate(0.000000, 17.000000)">
                            <polygon transform="translate(659.499962, 26.000000) rotate(180.000000) translate(-659.499962, -26.000000) " points="659.499962 20 665.499962 32 653.499962 32"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )

}

export default IconSort;
